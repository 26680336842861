import { Box, Container, Stack, Typography, useMediaQuery } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { Button, theme } from 'ui'

export const SellPageBanner = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container
      sx={{
        mt: '94px',
        display: 'grid',
        gridTemplateRows: isMobile ? '1fr 1fr 1auto' : '1fr 1fr 1fr',
        gridTemplateColumns: '1fr 1fr 1fr',
      }}
    >
      <Box
        display="flex"
        gridColumn="1 / 4"
        gridRow={{ xs: '1/2', md: '1 / 4' }}
        width="100%"
        justifyContent={{ xs: 'center', md: 'flex-end' }}
        alignItems="flex-end"
      >
        <Image
          src={isMobile ? '/images/house.webp' : '/images/alt_banner.webp'}
          alt="Homeoptions Banner"
          width={isMobile ? 464 : 566}
          height={isMobile ? 364 : 740}
          priority
          style={{
            objectFit: 'contain',
          }}
        />
      </Box>

      {!isMobile && (
        <Stack gap={5} py={2} zIndex={1} gridColumn="1 / 4" gridRow="1 / 4">
          <Typography mt={6} color="primary" variant="h1" zIndex={10} fontSize="3rem">
            Ready to sell? <br />
            Let us match you <br />
            with your perfect <br />
            agent.
          </Typography>
          <Typography variant="body1">
            HomeOptions connects you to top real estate
            <br /> agents in your area to help your every need.
          </Typography>
          <Link href="/sell/options" className="w-full">
            <Button variant="contained" color="secondary" className="px-16">
              Get started today
            </Button>
          </Link>
        </Stack>
      )}
      {isMobile && (
        <>
          <Stack zIndex={1} gridColumn="1 / 4" gridRow="1 / 2">
            <Typography color="primary" variant="h1" zIndex={10} fontSize="1.75rem">
              Ready to sell? <br />
              Let us match you <br />
              with your perfect <br />
              agent.
            </Typography>
          </Stack>
          <Link href="/sell/options" className="col-span-3 my-4 w-full">
            <Button variant="contained" color="secondary" fullWidth>
              Get started today
            </Button>
          </Link>
        </>
      )}
    </Container>
  )
}
