import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useState } from 'react'
import { AnimatedAvatarMainCard } from '../AnimatedAvatarMainCard'
import { AnimatedAvatarSideCard } from '../AnimatedAvatarSideCard'
import { AnimatedAvatarCard, Direction } from '../AnimatedCardsSettings'

const opinions: AnimatedAvatarCard[] = [
  {
    name: 'Lisa Ford',
    description: `Born and raised in Lake Ronkokoma, LI, NY, Lisa loves making a difference in others’ lives. She is a flexible professional with numerous awards as a top real estate agent in her area.`,
    license: 'FA100077971',
    location: `Canon City, CA`,
    pic: `/images/lisa.webp`,
    transactions: 141,
    rating: '5.0',
    zillow_url: 'https://www.zillow.com/profile/LisaFordExitRealty/',
    full_bio: `Lisa, born and raised in Lake Ronkokoma, LI, NY, spent many years in Hobe Sound, FL before moving with her young family to colorful Colorado in 1994. Having lived all over the mountain territories from Cañon City to the Western Slope, Lisa has immersed herself in mountain living, now calling Cotopaxi home with her husband Chris and her two pugs. Before realizing her love of Real Estate, Lisa spent many years raising her children and working in law enforcement. Her love for making a difference in others lives and her nurturing nature led her to getting her Colorado Real Estate License in 2018.
Lisa has bought and sold many homes in her lifetime and understands the challenges that buying and selling a home can have. Her ability to adapt to change,her professionalism, and knowledge of the local market drives her to give 120% to her clients now and in the future. Her reputation continually brings her referral and repeat clients.
Lisa has received numerous awards as a top real estate professional in her area and is the go-to mountain Realtor.
In Lisa's free time, she enjoys relaxing with a good book or exploring the great outdoors with her husband Chris. You can often find her and Chris relaxing and fishing off their boat in the high mountain lakes of Colorado. Lisa especially enjoys spending time with her grown children and her granddaughter!
`,
  },
  {
    name: 'Jason Thoele',
    description: `If you're looking for an agent that will put you first while keeping communication, integrity and ethics at the forefront of business, then look no further. Jason employs a simple statement: "Integrity & Simplicity before Profitability".`,
    license: '01732808',
    location: `Los Angeles, CA`,
    pic: `/images/jason.webp`,
    transactions: 1304,
    rating: '5.0',
    zillow_url: 'https://www.zillow.com/profile/jasonthoele/',
    full_bio: `My name is Jason Thoele and I am the Managing Broker for Tholco Real Estate Group.   If you're looking for an agent that will put you first while keeping communication, integrity and ethics at the forefront of our business then look no further. My company and I employ a simple statement; "Integrity & Simplicity before Profitability".  These 4 simple words keep us on the right track and help us best serve our clients.  Some people believe it's important to note that since being licensed in 2006 I've sold more than 1,200 homes valuing over $300 million in real estate but I don't like to get caught up on the numbers.  What's more important to me is you and your real estate goals, I'll make sure that you're always number one!   My commitment as your local Realtor is to provide you with the specialized real estate service you deserve.  When you're looking for an agent that has your best interest in mind you've come to the right place.  I look forward to serving your real estate needs and adding you to our growing list of satisfied clients!   Call me today for immediate and professional service for all your real estate questions and needs.`,
  },
  {
    name: 'Robert Gluskin',
    description: `Robert is a real estate agent specializing in buying, selling and marketing luxury homes and estates throughout Las Vegas and Henderson, NV. Renowned for his unique concepts and innovative ideas, he sets his sales and marketing strategies apart from the rest.`,
    license: 'S.0175637.LLC',
    location: `Las Vegas, NV`,
    pic: `/images/robert.webp`,
    transactions: 223,
    rating: '5.0',
    zillow_url: 'https://www.zillow.com/profile/RobertGluskin/',
    full_bio: `Robert Gluskin is a real estate agent specializing in buying, selling and marketing luxury homes and estates throughout Las Vegas and Henderson, NV.
Las Vegas is a unique market and demands the expertise of the results oriented Mega-Agent, Robert Gluskin. Renowned for his unique concepts and innovative ideas, he sets his sales and marketing strategies apart from the rest. He is always on the cutting-edge of creative sales, marketing and utilizing state-of-the-art technology to develop real estate listing and sales strategies that produce unprecedented results.
Robert Gluskin was awarded YPN’s Top 40 Under 40 continuously from 2016-2018, as well as Hall of Fame 40 Under 40 in 2019, and MyVegas Magazine’s Top 100 Men of Success and Top 40 Under 40 Future Professionals of 2019 as well.
Robert Gluskin has also been featured as a Las Vegas Real Estate Correspondent on HGTV’s “Flip or Flop: Vegas”.
Originally from Brooklyn, NY, Robert Gluskin was raised in Las Vegas, NV. Robert attended Bonanza High School where he played soccer, basketball, volleyball, and ran track-and-field. From the time he was 16 years old until 24, he had always worked in the restaurant industry, from the Suncoast Hotel and Casino's Cafe Siena, to Gilley's and Señor Frogs at Treasure Island. Robert attended UNLV, and was a member of the Alpha Tau Omega fraternity.
In his free time, he enjoys spending time with his wife, who he married on May 4, 2014 (no, not because of Star Wars, that was just a coincidence) and his daughter, Brooklyn Nanette Gluskin, born May 23, 2015, who he considers (in the words of Stevie Ray Vaughan), his “little pride and joy”.
`,
  },
  {
    name: 'Patrick Pate',
    description: `Patrick is a family man serving the Emerald Coast and surrounding areas in FL. He looks forward to helping clients with all of their real estate needs, from first time home buyers to seasoned investors.`,
    license: '3437592',
    location: `Emerald Coast, FL`,
    pic: `/images/patrick.webp`,
    transactions: 119,
    rating: '5.0',
    zillow_url: 'https://www.zillow.com/profile/ppate0/',
    full_bio: `My name is Patrick Pate. I am a local realtor with Beach Haven Realty. I am licensed in Florida and Alabama and I serve the Emerald Coast and surrounding areas. I am a family man who loves God, my wife, kids and grandkids. I look forward to helping clients with all of their real estate needs from first time home buyers to seasoned investors. 
I would love the opportunity to help you and your family make your "Beach Life Dreams" come true. 
`,
  },
  {
    name: 'Cindy Chavira',
    description: `With a strong work ethic, integrity and on-going communication, Cindy is a top producing realtor. Her ultimate goal is to make the process of buying and selling your home as easy as possible, with limited stress and issues.`,
    license: '3065676',
    location: `Panama City Beach, FL`,
    pic: `/images/cindy.webp`,
    transactions: 284,
    rating: '-',
    zillow_url: 'https://www.zillow.com/profile/cindychavira1/',
    full_bio: `I moved to Panama City Beach in 2003, after living in New Hampshire for 38 years. During my 16-year diverse career in the business travel industry, I spent 14 years managing multi-million dollar accounts, before deciding it was time to move to Florida to be closer to my family that had migrated. It was the best decision I ever made. This is definitely Home!
Since obtaining my real estate license in 2003 I have remained a multi-million dollar producing Realtor consistently and have earned numerous awards to include Gold and Platinum Club for the Bay County Association of Realtors, Top Producing Alpine Club, and Berkshire Hathaway HomeServices Honor Society and Leading-Edge Society awards. In 2006 I decided to advance my knowledge and obtained my Brokers license ultimately opening Own It Real Estate LLC in 2017.  I was voted Best of Bay Real Estate Agent by the News Herald Readers Choice in 2018, 2019 & 2020, and my company was voted Best of Bay Real Estate Company in 2018, and 2nd place in 2019, and first place again in 2020, which are all huge accomplishments that I am very proud of.
I have been so fortunate to have had a successful career in real estate and truly believe it has been due to a strong work ethic, integrity, on-going communication, and that I am always available for my customers. This is not a part time job for me, I work for you full time! My ultimate goal is to make the process of buying and selling your home or investment property as easy as possible, with limited stress or issues.
I have an exceptionally loyal customer base which provides me with referrals and repeat business and I am so fortunate that I have built my career enough to allow others to trust me with one of their most valuable investments. If you choose to work with me I promise to provide you the best level of service while maintaining integrity, excellent communication, and seamless assistance to you for every aspect of your home purchase or sale.
I have been married since 1987 to Carlos Chavira, who is also a Realtor with Own It Realty LLC, which allows us to work well together as a team, greatly enhancing the quality of service. My son Brandon owns Silverworks Production and does Real Estate photography, and my other son Tyler spent 5 years in the Marines and lives in Tampa with a fabulous job with Intuitive Surgical.  I also have three wonderful Grandchildren Ali, Ryan, and Luke who are the light of my life and keep me young daily.
`,
  },
]

export const AgentProfiles = () => {
  const [selectedOptions, setSelectedOptions] = useState<{
    index: number
    direction: Direction
  }>({
    index: 0,
    direction: 'left',
  })

  const previousOptions = {
    index: selectedOptions.index === 0 ? opinions.length - 1 : selectedOptions.index - 1,
    direction: selectedOptions.direction,
  }

  const nextOptions = {
    index: selectedOptions.index === opinions.length - 1 ? 0 : selectedOptions.index + 1,
    direction: selectedOptions.direction,
  }

  const previous = () => {
    setSelectedOptions({ ...previousOptions, direction: 'left' })
  }

  const next = () => {
    setSelectedOptions({ ...nextOptions, direction: 'right' })
  }

  return (
    <Box
      position="relative"
      sx={{
        pt: 4,
        minHeight: { xs: 500, md: 400 },
        width: '100%',
      }}
    >
      <Box position="relative" minHeight="350px">
        <Box
          display={{ xs: 'none', md: 'block' }}
          position="absolute"
          sx={{
            background: 'linear-gradient(90deg, #fff 0%, transparent 100%)',
            visibility: { xs: 'hidden', md: 'visible' },
          }}
          height="100%"
          width="50%"
          left={0}
          zIndex={5}
        />
        <Box
          display={{ xs: 'none', md: 'block' }}
          position="absolute"
          sx={{
            background: 'linear-gradient(270deg, #fff 0%, transparent 100%)',
            visibility: { xs: 'hidden', md: 'visible' },
          }}
          height="100%"
          width="50%"
          right={0}
          zIndex={5}
        />
        <AnimatedAvatarSideCard
          side="left"
          direction={previousOptions.direction}
          next={next}
          previous={previous}
          opinion={opinions[previousOptions.index]}
        />
        <AnimatedAvatarMainCard
          direction={selectedOptions.direction}
          next={next}
          previous={previous}
          opinion={opinions[selectedOptions.index]}
        />
        <AnimatedAvatarSideCard
          side="right"
          direction={nextOptions.direction}
          next={next}
          previous={previous}
          opinion={opinions[nextOptions.index]}
        />
      </Box>
      <ChevronLeftRounded
        style={{
          position: 'absolute',
          top: 'calc(50% - 48px)',
          width: 48,
          height: 96,
          cursor: 'pointer',
          zIndex: 10,
        }}
        onClick={() => previous()}
      />
      <ChevronRightRounded
        style={{
          position: 'absolute',
          top: 'calc(50% - 48px)',
          right: 0,
          width: 48,
          height: 96,
          cursor: 'pointer',
          zIndex: 10,
        }}
        onClick={() => next()}
      />
    </Box>
  )
}
