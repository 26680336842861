import { ChevronDownIcon, ChevronUpIcon, StarIcon } from '@heroicons/react/24/solid'
import { theme } from '@homeoptions/dev.theme'
import { Avatar, Dialog, Typography, useMediaQuery } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { FC, useState } from 'react'
import {
  AnimatedAvatarCard,
  Direction,
  swipeConfidenceThreshold,
  swipePower,
} from './AnimatedCardsSettings'

export const AnimatedAvatarMainCard: FC<{
  opinion: AnimatedAvatarCard
  direction: Direction
  next: () => void
  previous: () => void
}> = ({ opinion, direction, next, previous }) => {
  const minSize = useMediaQuery(theme.breakpoints.up('md'))
  const [bioOpen, setBioOpen] = useState(false)

  return (
    <AnimatePresence custom={direction}>
      <motion.div
        key={opinion.name}
        style={{
          position: 'absolute',
          width: minSize ? '35%' : '80%',
          left: minSize ? 'calc(50% - 17.5%)' : 'calc(50% - 40%)',
          minHeight: minSize ? '400px' : '500px',
          height: '100%',
          background: '#f2f6fe',
          padding: '2rem',
          borderRadius: '1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          zIndex: 10,
        }}
        initial="enter"
        animate="center"
        exit="exit"
        custom={direction}
        variants={{
          enter: (direction: Direction) => ({
            x: direction === 'right' ? '100%' : '-100%',
            scale: 0.8,
            transition: {
              stiffness: 1000,
              damping: 100,
            },
          }),
          center: {
            scale: 1,
            x: 0,
          },
          exit: (direction: Direction) => ({
            x: direction === 'left' ? '100%' : '-100%',
            scale: 0.8,
            opacity: 0,
            transition: {
              stiffness: 1000,
              damping: 100,
            },
          }),
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x)
          if (swipe < -swipeConfidenceThreshold) {
            next()
          } else if (swipe > swipeConfidenceThreshold) {
            previous()
          }
        }}
      >
        <div className="flex flex-col gap-2">
          <Avatar
            src={opinion.pic}
            sx={{
              position: 'absolute',
              top: '-50px',
              left: '50%',
              transform: 'translate(-50%)',
              height: 100,
              width: 100,
              mb: 2,
            }}
          />
          <Typography variant="subtitle1">{opinion.name}</Typography>
          <Typography variant="body1">{opinion.location}</Typography>
          <div className="flex items-center justify-between">
            <div className="flex flex-col items-center gap-1">
              <span className="font-body text-xs text-gray-500">Rating</span>
              {opinion.zillow_url && (
                <Link href={opinion.zillow_url} target="_blank">
                  <div className="flex items-center gap-1">
                    <Image src="/images/zillow.svg" alt="Zillow Logo" width={13} height={13} />
                    <span className="font-regular font-body text-xs">{opinion.rating}</span>
                    <StarIcon className="h-3 w-3 text-blue-600" />
                  </div>
                </Link>
              )}
            </div>
            <div className="flex flex-col items-center gap-1">
              <span className="font-body text-xs text-gray-500">Transactions</span>
              <span className="font-regular font-body text-xs">{opinion.transactions}</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span className="font-body text-xs text-gray-500">License #</span>
              <span className="font-regular font-body text-xs">{opinion.license}</span>
            </div>
          </div>
          <Typography variant="body2">{opinion.description}</Typography>
          <button
            className="my-2 flex items-center justify-center gap-2 font-body text-sm
            text-blue-600"
            onClick={() => setBioOpen(true)}
          >
            See more
            <ChevronDownIcon className="h-3 w-3" />
          </button>
          <Dialog
            open={bioOpen}
            onClose={() => setBioOpen(false)}
            PaperProps={{
              className: 'bg-brand-blue-light p-4 relative',
            }}
          >
            {opinion.zillow_url && (
              <Link href={opinion.zillow_url} target="_blank" className="absolute top-4 right-4">
                <Image src="/images/zillow.svg" alt="Zillow Logo" width={28} height={28} />
              </Link>
            )}
            <span className="font-body text-3xl font-bold">{opinion.name}</span>
            <span className="my-1 font-body text-xl">{opinion.location}</span>
            <span className="mb-2 font-body text-sm text-gray-500">{opinion.license}</span>
            {opinion.full_bio?.split(/\r?\n|\r|\n/g).map((p, i) => (
              <p key={i} className="my-1 font-body">
                {p}
              </p>
            ))}
            <button
              className="my-2 flex items-center justify-center gap-2 font-body text-sm
            text-blue-600"
              onClick={() => setBioOpen(false)}
            >
              See less
              <ChevronUpIcon className="h-3 w-3" />
            </button>
          </Dialog>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
