import { ChevronDownIcon, StarIcon } from '@heroicons/react/24/solid'
import { theme } from '@homeoptions/dev.theme'
import { Avatar, Typography, useMediaQuery } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import { FC } from 'react'
import {
  AnimatedAvatarCard,
  Direction,
  swipeConfidenceThreshold,
  swipePower,
} from './AnimatedCardsSettings'

export const AnimatedAvatarSideCard: FC<{
  direction: Direction
  opinion: AnimatedAvatarCard
  side: Direction
  next: () => void
  previous: () => void
}> = ({ direction, opinion, side, next, previous }) => {
  const minSize = useMediaQuery(theme.breakpoints.up('md'))
  return minSize ? (
    <AnimatePresence custom={direction}>
      <motion.div
        key={opinion.name}
        style={{
          position: 'absolute',
          width: '35%',
          left: side === 'left' ? '0' : '65%',
          minHeight: '380px',
          height: '100%',
          background: '#f2f6fe',
          padding: '2rem',
          borderRadius: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        initial="enter"
        animate="center"
        exit="exit"
        custom={direction}
        variants={{
          enter: (direction: Direction) => ({
            x: direction === side ? 0 : direction === 'left' ? '-95%' : '95%',
            scale: direction === side ? 1 : 0.8,
            opacity: 0,
            transition: {
              stiffness: 1000,
              damping: 100,
            },
          }),
          center: {
            scale: 0.8,
            opacity: 1,
            x: 0,
          },
          exit: (direction: Direction) => ({
            x: direction !== side ? 0 : direction === 'right' ? '-95%' : '95%',
            scale: direction === side ? 1 : 0.8,
            opacity: 0,
            transition: {
              stiffness: 1000,
              damping: 100,
            },
          }),
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x)
          if (swipe < -swipeConfidenceThreshold) {
            next()
          } else if (swipe > swipeConfidenceThreshold) {
            previous()
          }
        }}
      >
        <div className="flex flex-col gap-2">
          <Avatar
            src={opinion.pic}
            sx={{
              position: 'absolute',
              top: '-40px',
              left: '50%',
              transform: 'translate(-50%)',
              height: 80,
              width: 80,
              mb: 2,
            }}
          />
          <Typography variant="subtitle1">{opinion.name}</Typography>
          <Typography variant="body1">{opinion.location}</Typography>
          <div className="flex items-center justify-between">
            <div className="flex flex-col items-center gap-1">
              <span className="font-body text-xs text-gray-500">Rating</span>
              {opinion.zillow_url && (
                <div className="flex items-center gap-1">
                  <Image src="/images/zillow.svg" alt="Zillow Logo" width={13} height={13} />
                  <span className="font-regular font-body text-xs">{opinion.rating}</span>
                  <StarIcon className="h-3 w-3 text-blue-600" />
                </div>
              )}
            </div>
            <div className="flex flex-col items-center gap-1">
              <span className="font-body text-xs text-gray-500">Transactions</span>
              <span className="font-regular font-body text-xs">{opinion.transactions}</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span className="font-body text-xs text-gray-500">License #</span>
              <span className="font-regular font-body text-xs">{opinion.license}</span>
            </div>
          </div>
          <Typography variant="body2">{opinion.description}</Typography>
          <div
            className="my-2 flex items-center justify-center gap-2 font-body text-sm
            text-blue-600"
          >
            See more
            <ChevronDownIcon className="h-3 w-3" />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  ) : null
}
