import { Faq } from '@homeoptions/dev.layout.faq'
import { Grid, Stack, Typography } from '@mui/material'

const defaultFaqData = [
  {
    question: 'What does HomeOptions do?',
    answer: `We pay homeowners now for a chance to work with them in the future. As a homeowner, we offer you up to $2,000 in exchange for the opportunity to help you in the future **if** you ever decide to sell your home. The money we provide you is not a loan, and it’s **never** repaid. **You keep the money regardless of if you ever sell**.`,
  },
  {
    question: 'How does HomeOptions make money?',
    answer: `We make money by receiving a referral fee from your future agent as well as any other services that you purchase through our platform.`,
  },
  {
    question: 'Are there any hidden fees?',
    answer: `No, this is not a loan; there is no repayment, interest, or hidden fees.`,
  },
  {
    question: 'Who is eligible to apply?',
    answer: `Everyone who owns a property/part of a property is welcome to apply. There are no credit checks involved.`,
  },
  {
    question: 'What if I decide to not sell my home?',
    answer: `That’s okay; Many people signing up with us have no plans to sell. The money is yours to keep.`,
  },
  {
    question: 'Can I choose my own agent?',
    answer: `Simply connect us with your preferred agent, and once they’ve successfully joined our network, you are free to list your home through them.`,
  },
]

const agentFaqData = [
  {
    question: 'What do I do when I want to sell my home?',
    answer: `Simply enter your address at the top of the page and follow prompts to submit your request. Alternatively you are welcome to reach out to us either by phone at 1-800-357-9992 from 9am-5pm PST or by email at contact@homeoptions.ai.`,
  },
  {
    question: 'Why should I use a HomeOptions agent?',
    answer: `At HomeOptions, we only work with the best! All our agents are top 25% real estate agents who have at least 2 years of experience in your local metropolitan area.`,
  },
  {
    question: 'How long will it take for me to be connected to an agent?',
    answer: `To ensure you are paired with the best possible agent for your property and preferences, our representatives work diligently to screen and match you to your perfect agent within 24 hours.`,
  },
  {
    question: 'If I have questions throughout the listing process, who do I ask?',
    answer: `Your agent is always there to help guide you, but if you ever have any additional questions or concerns, please do not hesitate to reach out to our support line at 1-800-357-9992 from 9am-5pm PST or email us at contact@homeoptions.ai to speak with a HomeOptions representative.`,
  },
  {
    question: 'Do I have to pay anything upon listing my home?',
    answer: `Nope! At HomeOptions, we pay you to list your home by offering up to $3000 as a one-time upfront cash offer. You are never required to pay this amount back or pay any other fees down the line. Just claim your cash offer and let us do the work for you!`,
  },
]

const FaqSupportText = () => (
  <Stack>
    <Typography
      variant="body1"
      paragraph
    >{`Don't see the answers for your questions here?`}</Typography>
    <Typography variant="button" color="primary" textTransform="none" fontWeight="600">
      {`Call us at`}
    </Typography>
    <Typography variant="h5" fontWeight={600} color="primary" paragraph>
      <a href="tel:800-357-9992">{`(800) 357-9992`}</a>
    </Typography>
    <Typography variant="body2">
      {`Monday to Friday 6:00AM to 6:00PM `}
      <b>Pacific Standard Time</b>
    </Typography>
    <Typography variant="body2">
      {`Weekends 9:00AM to 6:00PM `}
      <b>Pacific Standard Time</b>
    </Typography>
  </Stack>
)

type FaqSectionProps = {
  type?: 'default' | 'agent'
}
const FaqSection = ({ type }: FaqSectionProps) => (
  <>
    <Typography variant="h2" fontSize={{ xs: '2rem', md: '3rem' }} color="primary" mb={2}>
      {`FAQ`}
    </Typography>
    <Grid container spacing={3} mb={4}>
      <Grid item sx={{ display: { xs: 'none', md: 'flex' } }} xs={12} lg={6}>
        <FaqSupportText />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Faq
          data={type === 'agent' ? agentFaqData : defaultFaqData}
          sx={{ bgcolor: 'transparent' }}
        />
      </Grid>
      <Grid item sx={{ display: { xs: 'flex', md: 'none' } }} xs={12} lg={6}>
        <FaqSupportText />
      </Grid>
    </Grid>
  </>
)

export default FaqSection
