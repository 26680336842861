export type Direction = 'left' | 'right'
export type AnimatedCard = {
  name: string
  description: string
  rating: number
  url: string
}
export type AnimatedAvatarCard = {
  name: string
  description: string
  location: string
  rating?: string
  zillow_url: string
  transactions: number
  full_bio: string
  license?: string
  pic: string
}

export const swipeConfidenceThreshold = 1000
export const swipePower = (offset: number, velocity: number) => Math.abs(offset) * velocity
